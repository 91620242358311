import React from "react"
import styles from "./footer.module.css"
import Container from "./container"

export default ({ footerAddress }) => {
  return (
    <footer className={styles.footer}>
      <Container>
        <div
          dangerouslySetInnerHTML={{
            __html: footerAddress,
          }}
        />
        <div className={styles.logos}>
          <a href="https://www.krav.se">
            <svg viewBox="0 0 42.9 28.4" className={styles.krav}>
              <path d="M5.94 10.23v9.38h2.38v-4.26l.11.14 3.21 4.12h3.09l-4.11-4.94 3.77-4.44h-2.95l-3.01 3.91-.11.15v-4.06H5.94zm30.39 9.38l3.9-9.38h-2.59l-2.36 5.85-2.35-5.85h-2.59l3.88 9.38h2.11zm-14.19-6.52c0-1.82-1.19-2.86-3.27-2.86H15.2v9.38h2.38v-3.82l.11.17L20 19.61h5l.68-1.61h3.51l.62 1.65h2.54l-3.57-9.38h-2.64l-2.73 7a2.2 2.2 0 0 0-.09.21c-.29.77-.55 1.11-.83 1.12s-.5-.29-.81-.69L20 15.74h.09a2.5 2.5 0 0 0 2.05-2.65zm5.31-.18l1.1 3.27h-2.22zm-9.57 1.57h-.3V12h.3c.76 0 1.78.12 1.78 1.23s-1.02 1.25-1.78 1.25z" />
              <path d="M42.9 13.93C42.9 6.14 33.44 0 21.78 0 11.74 0 3.66 5.53 2 10.37c.34-2.84 3.18-6.78 8-9-6.52 2.45-10 7-10 12.3 0 4.2 2.67 8 6.93 10.62a27.16 27.16 0 0 0 14.85 4.11 30.83 30.83 0 0 0 4.77-.4 22.3 22.3 0 0 0 9.5-3.19 18.77 18.77 0 0 0 2.16-1.58 18.81 18.81 0 0 1-1.84 1.2c-3.53 1.84-8.26 2.71-13.56 2.71-11 0-19.88-5-19.88-11.77 0-6.33 7-11 16.71-11.64C31.24 3 35.75 7.86 36.3 8.63c-2.38-3.55-7.25-6.29-16.88-6C13.17 2.88 7 5.31 4.12 8.8c2.64-4.57 10-7.6 18.16-7.6 11.26 0 20.27 5.46 20.27 12.8a11.24 11.24 0 0 1-1.3 5.43 10.22 10.22 0 0 0 1.65-5.5z" />
              <path d="M39.35 19.61a1.17 1.17 0 1 0-1.16-1.17 1.17 1.17 0 0 0 1.16 1.17zm0-2.14a1 1 0 1 1-1 1 1 1 0 0 1 1-1z" />
              <path d="M39.09 18.5h.24l.34.61h.21l-.33-.61a.32.32 0 0 0 .35-.35c0-.28-.21-.35-.45-.35h-.55v1.31h.19zm0-.51h.33c.15 0 .29 0 .29.18s-.24.14-.42.14h-.2z" />
            </svg>
          </a>
          <a href="https://www.instagram.com/liaberggronsaker/">
            <svg className={styles.instagram} viewBox="0 0 28.4 28.4">
              <path d="M0 14.18V7.41a6.93 6.93 0 0 1 2-4.74A7.78 7.78 0 0 1 6 .32 9.23 9.23 0 0 1 8 0h12.27a8 8 0 0 1 7 3.82A7.45 7.45 0 0 1 28.4 8v12.39a7.6 7.6 0 0 1-4.88 7.32 7.42 7.42 0 0 1-3.12.64H8.21A8.22 8.22 0 0 1 2.25 26 7.09 7.09 0 0 1 0 20.8v-6.62zm2.72 0v6.54a4.54 4.54 0 0 0 .41 1.85 5.17 5.17 0 0 0 4.92 3.07c4 .06 8 0 12 0a6.81 6.81 0 0 0 2.19-.38 5 5 0 0 0 3.41-4.8V7.99a5.35 5.35 0 0 0-.18-1.55 5.13 5.13 0 0 0-4.73-3.64c-4.14-.09-8.29 0-12.43 0A6 6 0 0 0 6 3.18a5 5 0 0 0-3.28 4.68c.04 2.14.01 4.22.01 6.33z" />
              <path d="M14.17 21.43a7.24 7.24 0 1 1 7.27-7.1 7.21 7.21 0 0 1-7.27 7.1zm-4.7-7.22a4.73 4.73 0 1 0 9.45 0 4.73 4.73 0 1 0-9.45 0zm12.41-9.35a1.76 1.76 0 0 1 1.81 1.77 1.82 1.82 0 0 1-1.78 1.83 1.85 1.85 0 0 1-1.82-1.79 1.79 1.79 0 0 1 1.79-1.81z" />
            </svg>
            <span className="sr-only">@liaberggronsaker</span>
          </a>
        </div>
      </Container>
    </footer>
  )
}
