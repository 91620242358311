import React from "react"
import styles from "./header.module.css"
import Container from "./container"

export default ({ siteMetadata, logo }) => {
  return (
    <header className={styles.header}>
      <Container>
        <h1>
          <span className="sr-only">Liaberg Grönsaker</span>
          <img src={logo.publicURL} alt="" />
        </h1>
      </Container>
    </header>
  )
}
